import React from 'react'
import './Paywall.css'
import {Modal} from "@material-ui/core";
import {StripeConnector} from "../../connectors/stripe/StripeConnector";
import PricingPlan from "../pricing/PricingPlan";

let stripeConnector = new StripeConnector()

export default function Paywall(props) {

    const handleLogout = async () => {
        await props.logout()
        props.openHome()
    }

    const logoutAndOpenLogin = async () => {
        await props.logout()
        props.openLogin()
    }

    return (
        <Modal
            style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
            open={true}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description">
            <div className='paywallMainContainer'>
                <span className='paywall-header-text'>You don't have a valid subscription!</span>
                <PricingPlan actionOne={() => {
                    //TODO change naming
                    stripeConnector.createPaymentSession({
                        organisationId: props.user.userData.organisations[0],
                        userEmail: props.user.userEmail
                    }, true).then()
                }}/>
                <div className={'paywall-bottom-button-container'}>
                <u className={'paywall-login'} onClick={() => {
                    logoutAndOpenLogin().then() //TODO Martin ?
                }}>Login with another account</u>
                <u className={'paywall-logout'} onClick={() => {
                    handleLogout().then() //TODO Martin ?
                }}>Logout</u>
                </div>
            </div>
        </Modal>
    );
}
