import React from "react";
import './PricingPlan.css'
import BadgeWrapper from "../badgewrapper/BadgeWrapper";
export default function PricingPlan(props) {
    return (
        <div className='homePagePricingPlanContainer'>
            <BadgeWrapper text="Beta">
                <div className='homePagePricingPlan' onClick={() => {
                    props.actionOne()
                }}>
                    <span className='homePagePricingPlanBadge'>Starting Plan</span>
                    <span className="euro">14 days trial, then</span>
                    <div className='homePagePricingPlanPriceContainer'>

                        <span className="euro">€</span>
                        <span className="price">420</span>
                        <span className="userMonth">year / up to 5 users</span>
                    </div>
                    <div className='homePagePricingPlanOptionsContainer'>
                        <span className="textOption">Unlimited Feedback Reports</span>
                        <span className="textOption">Collaboration Features</span>
                        <span className="textOption">Screenshots and Annotations</span>
                        <span className="textOption">All Current and Future Integrations</span>
                    </div>
                </div>
            </BadgeWrapper>
        </div>
    )
}