import {ReactComponent as Jira} from '../../images/logo_jira.svg'
import {ReactComponent as Trello} from '../../images/logo_trello.svg'
import taskchain from '../../images/taskchain_logo.png'
import React from 'react'
import {Divider} from "@material-ui/core";
import {Text} from "../text/Text";


export default function Partner() {
    return (
        <div style={{marginTop: 100, marginBottom: 100, display: "flex", flexDirection: 'column', width: '60%', maxWidth: 1200, alignSelf:'center', alignItems:'center', justifyContent:'start'}}>
            <Text light opacity={0.5}>connect projects from</Text>
            <div style={{marginTop:40, display: "flex", flexDirection: 'row', width: '100%', alignSelf:'center', justifyContent:'space-evenly'}}>
                <Divider/>
                <Jira style={{marginRight: 20, width:'100%', height:50, alignSelf:'center'}}/>
                <div style={{display:'flex', marginRight: 20, width:'100%', alignSelf:'center'}}>
                    <img src={taskchain} style={{width:'100%'}}/>
                </div>
                <Trello style={{marginRight: 20, width:'100%', height:35, alignSelf:'center'}}/>
            </div>
            <Text light opacity={0.5} marginTop={10}>and more</Text>
        </div>
    )
}