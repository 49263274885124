import React from 'react'
import './Header.css'
import {cancelSubscription, logout, reactivateSubscription} from "../../reducers/userReducer";
import {connect} from "react-redux";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {push, replace} from "connected-react-router";
import HeaderMenu from "./headermenu/HeaderMenu";


class Header extends React.Component {

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.user !== nextProps.user) {
            this.forceUpdate();
        }
    }

    render() {
        return (
            <div className='headerContainer'>
                <ArrowBackIcon style={{color: "white"}} onClick={() => {
                    if (window.location.pathname.includes('app/time-entries') || window.location.pathname.includes('app/archive') || window.location.pathname.includes('app/users') ) {
                        this.props.openApp();
                    }else if (window.location.pathname.includes('app')) {
                        this.props.openHomePage();
                    }
                }} className='backToHomePageIcon'/>
                <div className='optionsContainer'>
                    <HeaderMenu openUsers={this.props.openUsers} reactivateSubscription={this.props.reactivateSubscription} cancelSubscription={this.props.cancelSubscription} logout={this.props.logout} openHomePage={this.props.openHomePage} user={this.props.user}/>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    let user = state.userReducer.currentUser;

    return {
        user: user,
        accounts: state.accountReducer.accounts
    }
};

const mapDispatchToProps = dispatch => {
    return {
        openApp: () => dispatch(push('/app')),
        openUsers: () => dispatch(push('/app/users')),
        cancelSubscription: (dataObject) => dispatch(cancelSubscription(dataObject)),
        reactivateSubscription: (dataObject) => dispatch(reactivateSubscription(dataObject)),
        openHomePage: () => dispatch(replace('/')),
        logout: () => dispatch(logout()),
    }
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Header)
