import React, {useEffect} from 'react'
import {Route} from 'react-router-dom'
import Home from './containers/home/Home'
import Login from './containers/login/Login';
import Amplify from 'aws-amplify';
import awsConfig from './aws-exports';
import Registration from './containers/registration/Registration';
import HomePage from "./containers/homepage/HomePage";
import {Switch} from "react-router";

Amplify.configure(awsConfig);

const HomePricingRoute = () => {
    return <HomePage showPricing/>
}

const HomePaymentSuccessRoute = () => {
    return <HomePage paymentSuccess/>
}

const HomePaymentCanceledRoute = () => {
    return <HomePage paymentCanceled/>
}

const HomeArchive = () => {
    return <Home showArchive/>
}

const HomeInvitations = (props) => {
    return <Home showInvitations invitationId={props.match.params.invitationId} />
}

const HomeUsers = () => {
    return <Home showUsers/>
}

const HomePageBlog = (props) => {
    return <HomePage showBlog blogId={props.match.params.blogId}/>
}

const HomePagePrivacy = () => {
    return <HomePage showPrivacy/>
}

const HomePageTermsAndConditions = () => {
    return <HomePage showTermsAndConditions/>
}

const HomePageCookiePolicy = () => {
    return <HomePage showCookiePolicy/>
}

export const App = () => {

    return (
        <Switch>
            <Route path='/invitations/:invitationId' component={HomeInvitations}/>
            <Route path='/blog/:blogId' component={HomePageBlog}/>
            <Route path='/blog' component={HomePageBlog}/>
            <Route path='/registration' component={Registration}/>
            <Route path='/login' component={Login}/>[
            <Route path='/app/users' component={HomeUsers}/>
            <Route path='/app/archive' component={HomeArchive}/>
            <Route path='/app' component={Home}/>
            <Route path='/pricing' component={HomePricingRoute}/>
            <Route path='/payment-success' component={HomePaymentSuccessRoute}/>
            <Route path='/payment-canceled' component={HomePaymentCanceledRoute}/>
            <Route path='/privacy' component={HomePagePrivacy}/>
            <Route path='/cookie-policy' component={HomePageCookiePolicy}/>
            <Route path='/terms-and-conditions' component={HomePageTermsAndConditions}/>
            <Route path='/' component={HomePage}/>
        </Switch>
    );
}
