export function getIssueUniqueId(issueData) {
    return `${issueData.projectId}${SEPARATOR}${issueData.issue.key}`
}

export function getIssueKeyFromUniqueId(id) {
    if (id && id.includes(SEPARATOR)) {
        let parts = id.split(SEPARATOR);
        if (parts && Array.isArray(parts)) {
            return parts[1];
        }
    }

    return null
}

export function getProjectIdFromUniqueIssueKey(id) {
    if (id && id.includes(SEPARATOR)) {
        let parts = id.split(SEPARATOR);
        if (parts && Array.isArray(parts)) {
            return parts[0];
        }
    }

    return null
}



const SEPARATOR = '--';