import * as React from "react";
import {push, replace} from "connected-react-router";
import {connect} from "react-redux";
import {confirmSignUp, createAccount, setErrorMessage} from "../../reducers/userReducer";
import './Registration.css'
import {StyledButton} from "../../components/button/StyledButton";
import StyledTextField from "../../components/textfield/StyledTextField";
import TextButton from "../../components/button/TextButton";
import {StripeConnector} from "../../connectors/stripe/StripeConnector";
import {UNKNOWN_USERNAME, PREMIUM_PLAN_PRICE_ID_PRODUCTION} from "../../Constants";
import {Text} from "../../components/text/Text";

class Registration extends React.Component {

    constructor(props) {
        super();
        this.state = {
            confirmButtonText: "Create Account",
            code: null,
            showCodeField: false,
            name: null,
            email:  props.invitation ? props.invitation.receiverEmail : 'email',
            password: null
        }
    }


    handleKeyDown(event) {
        if (event.key === 'Enter') {
            this.handleCreateAccount();
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.initiatePaymentSession) {
            this.createPaymentSession(nextProps.user, {
                priceId: PREMIUM_PLAN_PRICE_ID_PRODUCTION
            })
        } else if (nextProps.errorMessage) {
            setTimeout(() => {
                this.props.clearErrorMessage()
            }, 5000)
        }
    }

    setValue(value, type) {
        if (type === 'name') {
            this.setState({
                name: value
            })
        } else if (type === 'password') {
            this.setState({
                password: value
            })
        } else if (type === 'email') {
            this.setState({
                email: value
            })
        }
    }

    async createPaymentSession(user, dataObject) {
        let username = user ? user.userEmail : UNKNOWN_USERNAME;

        await new StripeConnector().createPaymentSession({
            username: username,
            userEmail: username,
            priceId: dataObject.priceId
        }, true)
    }

    render() {
        let registrationButtonTopMargin = this.props.errorMessage ? 50 : 80;
        let emailHint = this.props.invitation ? this.props.invitation.receiverEmail : null;

        return (
            <div className="registrationContainer" onKeyDown={this.handleKeyDown.bind(this)}>
                <div className="registrationFieldsContainer">
                    <form className="registrationForm" noValidate autoComplete="off">
                        <StyledTextField type='name' setValue={this.setValue.bind(this)}/>
                        <StyledTextField disabled={!!this.props.invitation} placeholder={emailHint} type='email' setValue={this.setValue.bind(this)}/>
                        <StyledTextField type='password' setValue={this.setValue.bind(this)}/>

                        {this.props.errorMessage ? <Text marginTop={10} error>{this.props.errorMessage}</Text> : null}

                        <StyledButton
                            marginTop={registrationButtonTopMargin}
                            onClick={() => {
                                this.handleCreateAccount();
                            }}>{this.state.confirmButtonText}</StyledButton>
                        <div className={'registration-sign-in'}>
                            {!this.props.invitationData ? <span>Already have an account? <u onClick={() => {
                                this.props.clearErrorMessage()
                                this.props.openLogin()
                            }}>Sign in</u></span> : null}
                        </div>
                    </form>
                </div>
            </div>
        );
    }

    async handleCreateAccount() {
        let result = await this.props.createAccount({
            email: this.props.invitation ? this.props.invitation.receiverEmail : this.state.email,
            password: this.state.password,
            name: this.state.name,
            initiatePaymentSessionOnCreation: this.props.invitation === false,
            invitationId: this.props.invitation ? this.props.invitation._id : null,
            parentEmail: this.props.invitation ? this.props.invitation.senderEmail : null
        });

        if (result && result.payload && result.payload.user) {
            if (this.props.invitation) {
                this.props.openAppToInvitation(this.props.invitation._id);
            }else {
                this.props.openApp();
            }
        }
    }
}

const mapStateToProps = (state) => {
    return {
        initiatePaymentSession: state.userReducer.initiatePaymentSession,
        user: state.userReducer.currentUser,
        errorMessage: state.userReducer.errorMessage
    }
};

const mapDispatchToProps = dispatch => {
    return {
        openLogin: () => dispatch(push('/login')),
        openHome: () => dispatch(push('/')),
        openApp: () => dispatch(replace('/app')),
        openAppToInvitation: (invitationId) => dispatch(replace(`/invitations/${invitationId}`)),
        confirmSignUp: (dataObject) => dispatch(confirmSignUp(dataObject)),
        createAccount: (dataObject) => dispatch(createAccount(dataObject)),
        clearErrorMessage: () => dispatch(setErrorMessage(null))
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Registration)
